import {
    Content,
    ContentHeader,
    Header,
    Page,
    SupportButton,
} from '@backstage/core-components';

import {
    WelcomeTitle,
} from '@backstage/plugin-home';

import React from 'react';

export const HomePage = (
    <Page themeId="home">
        <Header title={<WelcomeTitle />} pageTitleOverride="Home" />
        <Content>
            <ContentHeader title="">
                <SupportButton>Your start page</SupportButton>
            </ContentHeader>
        </Content>
    </Page>
);
